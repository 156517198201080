<template>
  <div>
    <courses-menu-bar v-if="use.tenant.is_courses_menu_bar_visible" />
    <checkins-menu-bar v-else-if="use.tenant.is_checkins_menu_bar_visible" />

    <div class="w-100">
      <router-view />
    </div>
  </div>
</template>
<script>
import CheckinsMenuBar from '@public/components/checkins_menu_bar.vue'
import CoursesMenuBar from '@public/components/courses_menu_bar.vue'

export default {
  name: 'PublicRoot',

  async created() {
    this.grab_referral_source()

    if (this.gym) {
      this.$vl_time.set(this.gym.time_zone)

      // NOTE: Necessary to enable the url getter inside of tenant store
      this.use.tenant.home = { vl_gym_id: this.gym.id }
    }

    this.use.tenant.write_modules(this.minimal_modules)
    this.use.tenant.cloudfront = this.cloudfront
    this.use.tenant.api_url = this.smcb_api
    this.use.public_actor.is_logged_in = this.is_logged_in
    this.use.public_actor.current_user = this.current_user
    this.use.public_slots.iframe_mode = this.iframe
    this.use.tenant.default_client_id = this.default_client_id
    this.use.public_actor.delete_account_url = this.delete_account_url

    // TODO: get rid
    this.use.public_slots.consent_document_url = `${this.cloudfront}german-pharma/einverstaendnis.pdf`
  },

  props: {
    cloudfront: {
      type: String,
      required: true,
    },

    current_user: {
      type: Object,
      default: null,
    },

    default_client_id: {
      type: String,
      default: null,
    },

    delete_account_url: {
      type: String,
      default: null,
    },

    gym: {
      type: Object,
      default: null,
    },

    iframe: {
      type: Boolean,
      default: false,
    },

    is_logged_in: {
      type: Boolean,
      required: true,
    },

    minimal_modules: {
      type: Array,
      required: true,
    },

    smcb_api: {
      type: String,
      required: true,
    },
  },

  methods: {
    grab_referral_source() {
      const referral_source = new URLSearchParams(window.location.search).get('source')
      if (!this.$vl_utils.is_blank_trim(referral_source)) {
        this.$cookies.set('vl_checkins_referral_source', referral_source, '1h')
        window.history.pushState({}, '', window.location.href.replace(`source=${referral_source}`, ''))
      }
    },
  },

  components: {
    CheckinsMenuBar,
    CoursesMenuBar,
  },
}
</script>
