<template>
  <a :href="href" :class="[fill_container ? 'fill-container' : '']">
    <button
      class="button-container"
      :class="[
        `variant-${variant}`,
        `color-${color}`,
        `size-${size}`,
        icon_left ? 'icon-left' : '',
        icon_center ? 'icon-center' : '',
        icon_right ? 'icon-right' : '',
        fill_container ? 'fill-container' : ''
      ]"
      :disabled="disabled"
      v-on="$listeners"
    >
      <div v-if="icon_left" class="left-icon">
        <vl-icon size="lg" :icon="icon_left" />
      </div>

      <div v-if="icon_center" class="center-icon">
        <vl-icon size="lg" :icon="icon_center" />
      </div>

      <!-- If slot is present, render slot, otherwise fall back to the label -->
      <template v-if="$slots.default">
        <slot>
          <div class="button-text">{{ label }}</div>
        </slot>
      </template>

      <div v-if="icon_right" class="right-icon">
        <vl-icon size="lg" :icon="icon_right" />
      </div>
    </button>
  </a>
</template>

<script>
import VlIcon from '@core/shared/components/vl_icon.vue'

export default {
  name: 'VlButton',

  props: {
    label: {
      type: String,
      required: false,
      default: null
    },

    icon_left: {
      type: String,
      required: false,
      default: null
    },

    icon_center: {
      type: String,
      required: false,
      default: null
    },

    icon_right: {
      type: String,
      required: false,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    href: {
      type: String,
      required: false,
      default: null
    },

    variant: {
      type: String,
      required: false,
      default: 'fill',
      validator: function(value) {
        return ['fill', 'outline', 'link'].indexOf(value) !== -1
      }
    },

    color: {
      type: String,
      required: false,
      default: 'primary',
      validator: function(value) {
        return ['primary', 'secondary', 'danger'].indexOf(value) !== -1
      }
    },

    fill_container: {
      type: Boolean,
      required: false,
      default: false
    },

    size: {
      type: String,
      required: false,
      default: 'md',
      validator: function(value) {
        return ['sm', 'md'].indexOf(value) !== -1
      }
    }
  },

  components: {
    VlIcon
  },

}
</script>

<style lang="scss" scoped>
a, button {
  all: unset;
  width: fit-content;
  display: flex;
  white-space: nowrap;
}

.fill-container {
  width: 100% !important;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: fit-content;
  border-radius: 4px;
  color: var(--neutral-white_primary);
  cursor: pointer;
  text-transform: uppercase;

  &.size-sm {
    height: 28px;
    // @include body3_regular_uppercase;
  }

  &.size-md {
    height: 36px;
    // @include body2_regular_uppercase;
  }

  &.color-primary {
    --default-color: var(--plum-600-main);
    --hover-color: var(--plum-900);
    --disabled-color: var(--plum-100);
  }

  &.color-secondary {
    --default-color: var(--green-600-main);
    --hover-color: var(--green-900);
    --disabled-color: var(--green-100);
  }

  &.color-danger {
    --default-color: var(--alert-danger);
    --hover-color: var(--plum-900);
    --disabled-color: var(--plum-100);
  }

  &:not(.variant-link) {
    &.size-sm {
      padding-left: 8px;
      padding-right: 8px;
    }

    &.size-md {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .button-text {
    white-space: nowrap;
  }

  &.icon-left {
    &.size-sm {
      padding-left: 6px; // TODO implement correct size
    }

    &.size-md {
      padding-left: 6px;
    }
  }

  &.icon-right {
    &.size-sm {
      padding-right: 6px; // TODO implement correct size
    }

    &.size-md {
      padding-right: 6px;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.variant-fill {
    background-color: var(--default-color);

    &:hover {
      background-color: var(--hover-color);
    }

    &:disabled {
      background-color: var(--disabled-color);
    }
  }

  &.variant-outline {
    outline-offset: -1px;
    background-color: var(--neutral-white_primary);
    color: var(--default-color);
    outline: 1px solid var(--default-color);

    &:hover {
      color: var(--hover-color);
      outline: 1px solid var(--hover-color);
    }

    &:disabled {
      color: var(--disabled-color);
      outline: 1px solid var(--disabled-color);
    }
  }

  &.variant-link {
    color: var(--default-color);

    &:hover {
      color: var(--hover-color);
    }

    &:disabled {
      color: var(--disabled-color);
    }
  }

}
</style>
