import { defineStore } from 'pinia'
import { use_tenant } from '@public/stores/tenant_store'
import Vue from 'vue'

export const use_public_course_templates = defineStore('public_course_templates', {
  state: () => ({
    course_templates: [],
    course_templates_loaded: false
  }),

  actions: {
    async get_all_course_templates() {
      if (this.course_templates_loaded) return
      const url = `${use_tenant().api_home_url}/public/courses`
      const { data } = await Vue.smcb_axios.get(url)
      this.course_templates = data.courses
      this.course_templates_loaded = true
    }
  }
})
