import { defineStore } from 'pinia'
import { use_tenant } from '@public/stores/tenant_store'
import Vue from 'vue'

function parse_course(task) {
  return Object.freeze({
    ...task,
    start: task.start ? Vue.$vl_time.parse_as_local(task.start) : null,
    end: task.end ? Vue.$vl_time.parse_as_local(task.end) : null,
    bookable_from: task.bookable_from ? Vue.$vl_time.parse_as_local(task.bookable_from) : null,
    first: task.first ? Vue.$vl_time.parse_as_local(task.first) : null,
    last: task.last ? Vue.$vl_time.parse_as_local(task.last) : null,
  })
}

function parse_calendar_event(task, task_group) {
  return Object.freeze({
    ...task,
    start: Vue.$vl_time.parse_as_local(task.start).toJSDate(),
    end: task.end ? Vue.$vl_time.parse_as_local(task.end).toJSDate() : null,
    bookable_from: task.bookable_from ? Vue.$vl_time.parse_as_local(task.bookable_from) : null,
    allDay: task.is_all_day,
    title: task.name,
    color: task_group ? task_group.color : '#92278F',
  })
}

export const use_public_course_template = defineStore('public_course_template', {
  state: () => ({
    loaded_task_group_id: null,
    course_template: null,
    course_list_of_template: [],
    calendar_events_of_template: [],
    loaded_events_urls: [],
    last_viewed_course: null,
  }),

  actions: {
    reset_store() {
      this.course_list_of_template = []
      this.calendar_events_of_template = []
      this.loaded_events_urls = []
      this.last_viewed_course = null
      this.course_template = null
    },

    // this will load all the info needed for the page showing all the courses of a course template
    async load_course_list_of_template(task_group_id) {
      if (this.loaded_task_group_id === task_group_id) return
      this.reset_store()

      const url = `${use_tenant().api_home_url}/public/courses/${task_group_id}/list`
      const { data } = await Vue.smcb_axios.get(url)

      this.course_template = data.course_template
      this.loaded_task_group_id = task_group_id
      this.course_list_of_template = [...data.courses.map(task => parse_course(task))]
    },

    async load_calendar_events({ start, end }, task_group_id) {
      const urls = new Set()
      // this piece of logic will load all necessary months that come in via request
      for (let i = 0; start.plus({ months: i }).startOf('month') < end; i++) {
        const month = start.plus({ months: i })
        const url = `${use_tenant().api_home_url}/public/courses/${task_group_id}/calendar/${month.year}/${month.month}`

        if (!this.loaded_events_urls.includes(url)) {
          this.loaded_events_urls.push(url)
          urls.add(url)
        }
      }

      if (urls.size > 0) {
        return Promise.all(
          [...urls].map(url =>
            Vue.smcb_axios.get(url).then(response => {
              const data = response.data
              this.add_events_to_calendar(data.courses || [], data.course_template)
            })
          )
        )
          .then(() => {
            return this.calendar_events_of_template
          })
          .catch(error => {
            return error
          })
      } else {
        return Promise.resolve(this.calendar_events_of_template)
      }
    },

    add_events_to_calendar(tasks, task_group) {
      // this method will only add tasks that have not been added yet
      const oldTasks = this.calendar_events_of_template
      const newTasks = tasks.filter(t => !oldTasks.some(u => u.id === t.id)).map(task => parse_calendar_event(task, task_group))
      this.calendar_events_of_template.push(...newTasks)
    },
  },
})
