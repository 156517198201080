<template>
  <div class="public-user-image-container" :style="styleObject">
    <img v-if="!show_initials && avatar_url" class="user-round user-image" :src="avatar_url" @error="on_image_load_error()" />
    <div v-else :class="`user-round initials-circle ${font_mapping[size]}`">{{ user_initials }}</div>
  </div>
</template>

<script>
export default {
  props: {
    avatar_url: {
      type: String,
      required: false,
      default: null,
    },

    firstname: {
      type: String,
      required: false,
      default: '',
    },

    lastname: {
      type: String,
      required: false,
      default: '',
    },

    size: {
      type: String,
      required: false,
      default: 'md',
    },
  },

  data() {
    return {
      show_initials: false,
      circle_size_mapping: {
        sm: '24px',
        md: '36px',
      },

      font_mapping: {
        sm: 'body4-bold',
        md: 'body2-bold',
      },
    }
  },

  computed: {
    styleObject() {
      return {
        '--circle-size': `${this.circle_size_mapping[this.size]}`,
      }
    },

    user_initials() {
      const a = this.firstname?.slice(0, 1)
      const z = this.lastname?.slice(0, 1)
      return `${a}${z}`
    },
  },

  methods: {
    on_image_load_error() {
      this.show_initials = true
    },
  },
}
</script>

<style lang="scss" scoped>
.public-user-image-container {
  .user-round {
    width: var(--circle-size);
    height: var(--circle-size);
    border-radius: var(--circle-size);

    &.user-image {
      object-fit: cover;
    }

    &.initials-circle {
      background-color: var(--neutral-100_primary);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
