import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_tenant } from '@public/stores/tenant_store'
import pricings_lib from '@core/lib/pricings'

export const use_public_course = defineStore('public_course', {
  state: () => ({
    course: null,
  }),

  actions: {
    async load_single_course(task_id) {
      if (this.course && this.course.id === task_id) return

      // reset course to null to show that data is not present
      this.course = null

      const url = `${use_tenant().api_home_url}/public/course/${task_id}`
      const { data } = await Vue.smcb_axios.get(url)

      // convert time into correct format
      data.course.start = Vue.$vl_time.parse_as_local(data.course.start)
      data.course.end = Vue.$vl_time.parse_as_local(data.course.end)
      data.course.bookable_from = Vue.$vl_time.parse_as_local(data.course.bookable_from)

      if (data.course.first) data.course.first = Vue.$vl_time.parse_as_local(data.course.first)
      if (data.course.last) data.course.last = Vue.$vl_time.parse_as_local(data.course.last)
      this.course = data.course
    },
  },

  getters: {
    bookable_pricings(state) {
      if (!state.course?.ticket_required) return []
      return state.course?.pricings?.filter(el => el.type !== 'item') || []
    },

    items(state) {
      return state.course?.pricings?.filter(el => el.type === 'item') || []
    },

    is_flat_rate_booking() {
      return this.bookable_pricings.filter(pricings_lib.is_flat_rate_pricing).length > 0
    },

    spots_left(state) {
      return state.course?.spots_left
    },

    pricings(state) {
      return state.course?.pricings || []
    },

    show_participation_fees() {
      return this.pricings.length > 0 && !this.is_flat_rate_booking
    },
  },
})
