<template>
  <div class="container-fluid bg-white border-bottom py-3">
    <div class="row align-items-center">
      <div class="col d-none d-md-block"></div>

      <div class="col">
        <div class="d-flex justify-content-md-center align-items-center">
          <img
            v-if="use.tenant.settings?.logo_path"
            class="bg-light rounded-circle"
            width="36"
            height="36"
            :src="`${use.tenant.cloudfront}${use.tenant.settings.logo_path}_thumb.jpg`"
          />
          <div class="d-none d-md-block text-lg m-0 pl-2">{{ use.tenant.home?.name }}</div>
        </div>
      </div>

      <div class="col text-right">
        <div class="d-flex justify-content-end">
          <div v-if="show_login_link" id="top_menu_login_widget" class="align-self-center pr-4 pr-md-5">
            <div v-if="use.public_actor.is_logged_in" class="dropdown">
              <a
                id="signoutMenuButton"
                href="#"
                class="dropdown-toggle d-flex align-items-center"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <vl-icon icon="md_people" size="md" />
                <span class="px-2 text-nowrap d-inline d-md-none">{{ user_initials }}</span>
                <span class="px-2 text-nowrap d-none d-md-inline">{{ user.firstname }} {{ user.lastname }}</span>
              </a>
              <div class="dropdown-menu mr-4" aria-labelledby="signoutMenuButton" style="z-index: 2000">
                <a href="/logout" class="dropdown-item">{{ $t('header.logout') }}</a>
                <a
                  v-if="show_delete_account_button"
                  id="link_to_delete_account"
                  class="dropdown-item"
                  style="cursor: pointer"
                  @click="show_delete_account_modal = true"
                  >{{ $t('header.delete_account') }}</a
                >
              </div>

              <b-modal id="delete-account-modal" v-model="show_delete_account_modal" no-close-on-backdrop :title="$t('header.delete_account')">
                {{ $t('header.are_you_sure_you_want_to_delete_your_account') }}
                <template #modal-footer>
                  <button class="btn btn-secondary" @click="show_delete_account_modal = false">{{ $t('form.cancel') }}</button>
                  <a class="btn btn-danger" :href="populated_delete_account_url">{{ $t('form.delete') }}</a>
                </template>
              </b-modal>
            </div>
            <a v-else id="login_link" :href="login_url">
              <vl-icon icon="md_people" size="md" />
              {{ $t('core.login') }}
            </a>
          </div>

          <div class="align-self-center">
            <language-selector />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'

import LanguageSelector from '@public/components/language_selector.vue'
import VlIcon from '@core/shared/components/vl_icon.vue'

export default {
  data: function () {
    return {
      show_delete_account_modal: false,
    }
  },

  computed: {
    public_calendar_url() {
      return `/${this.use.tenant.home.slug}/calendar`
    },

    show_delete_account_button() {
      return this.is_running_noq && !this.use.public_actor.is_employee
    },

    user() {
      if (!this.use.public_actor.is_logged_in) return null
      return this.use.public_actor.current_user
    },

    user_initials() {
      const a = this.user?.firstname?.slice(0, 1)
      const z = this.user?.lastname?.slice(0, 1)
      return `${a} ${z}`
    },

    login_url() {
      const url = document.head.querySelector('meta[name="login-url"]').content
      return `${url}/${this.$route.fullPath.substring(1)}`
    },

    is_running_noq() {
      return this.$vl_utils.is_running_noq()
    },

    show_login_link() {
      // TODO: NQ-2994 Adapt to vue route name when we add headcount page
      return !window.location.pathname.includes('headcount')
    },

    populated_delete_account_url() {
      let url = this.use.public_actor.delete_account_url
      if (!url) return null

      const slug = this.use.tenant.home?.slug
      if (!slug) return null

      // On certain public pages we do not know the location we are serving until we contact Wilma
      url = url.replace('placeholder-slug', slug)

      // Absolute path is needed for the URL class to parse
      if (!url.includes('http')) {
        url = window.location.origin + url
      }

      // Append the redirect_url with correct escaping
      url = new URL(url)
      url.searchParams.append('redirect_url', window.location)

      return url.toString()
    },
  },

  components: {
    BModal,
    LanguageSelector,
    VlIcon,
  },
}
</script>
