import { defineStore } from 'pinia'
import Vue from 'vue'

export const use_utility = defineStore('utility', {
  state: () => ({
    is_mobile: false,
    isListening: false, // Flag to check if the resize listener is already added
  }),

  actions: {
    // Action to check the window width and update the state
    checkWindowSize() {
      this.is_mobile = window.innerWidth < 767
    },

    // Initialize the resize listener if not already set up
    startListeningToResize() {
      if (!this.isListening) {
        window.addEventListener('resize', this.checkWindowSize)
        this.checkWindowSize() // Initial call to set the correct value
        this.isListening = true
      }
    },

    // Remove the resize listener
    stopListeningToResize() {
      if (this.isListening) {
        window.removeEventListener('resize', this.checkWindowSize)
        this.isListening = false
      }
    },
  },
})

// Initialize the listener when the app mounts
Vue.mixin({
  mounted() {
    const windowStore = use_utility()
    windowStore.startListeningToResize()
  }
})
