import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_public_actor } from '@public/stores/public_actor_store'
import { use_public_slots } from '@public/stores/public_slots_store'
import { use_tenant } from '@public/stores/tenant_store'

function parse_anonymous_booking(booking) {
  return Object.freeze(booking)
}

export const use_public_bookings = defineStore('public_bookings', {
  state: () => ({
    canceled_booking_states: ['canceled', 'expired', 'refunded'],

    // TODO: NQ-3101 Unify same variable on public_slots_store
    recent_booking_data: null,
  }),

  actions: {
    async get_slot_booking_by_token(token) {
      const tenant = use_tenant()
      const { data } = await Vue.smcb_axios.get(`${tenant.api_home_url}/checkins/booking_by_token/${token}`)
      if (data?.booking && data?.participants) {
        tenant.contextual.service_settings = data.service_settings || {}
        return {
          booking: data.booking,
          booked_participants: data.participants.filter(p => p.status === 'booked'),
          slot: data.slot,
        }
      } else {
        return false
      }
    },

    async get_booking_status_by_token(token) {
      const { data } = await Vue.smcb_axios.get(`${use_tenant().api_home_url}/checkins/booking_status_by_token/${token}`)
      const ps = use_public_slots()
      ps.set_slot_areas(ps.slot_areas_by_service[data.service_id] || [])
      if (data?.booking && data?.participants) return data
      return false
    },

    async remove_slot_booking_by_token({ token, participant_ids }) {
      return await Vue.smcb_axios.delete(`${use_tenant().api_home_url}/checkins/bookings/${token}`, { data: { participant_ids } })
    },

    async save_task_booking(payload) {
      const tenant = use_tenant()
      const url = `${tenant.api_home_url}/public/course/${payload.task_id}/bookings`
      try {
        const { data } = await Vue.smcb_axios.post(url, payload)

        // if payment is required, redirect to the checkout page with the proper redirect url
        if (payload.booking.ticket_required && data.payment_redirect_url !== null) {
          window.location.href = `${data.payment_redirect_url}`
        } else {
          const { booking, items, participants, download_urls } = data
          this.recent_booking_data = { booking, items, participants, payload, download_urls: download_urls || null }
        }

        use_public_actor().load_customer_data()
      } catch (e) {
        if (e.response.status === 409) {
          this.recent_booking_data = { is_error: true, code: 'schedule_config_changed' }
        } else if (e.response.status === 410) {
          this.recent_booking_data = { is_error: true, code: 'overbooking' }
        } else if (e.response.status === 413) {
          this.recent_booking_data = { is_error: true, code: 'file_too_large' }
        } else {
          this.recent_booking_data = { is_error: true, message: e.message }
          throw e
        }
      }
    },

    async delete_uploaded_file({ participant_id, file_id }) {
      const url = `${use_tenant().api_home_url}/checkins/participants/${participant_id}/delete-file`
      await Vue.smcb_axios.post(url, { file_id })
    },
  },
})
