import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_public_course } from '@public/stores/calendar/public_course_store'
import { use_tenant } from '@public/stores/tenant_store'

const empty_participant = {
  address: null,
  age: null,
  city: null,
  country: null,
  date_of_birth: null,
  email: null,
  extra_fields: {},
  filename: null,
  firstname: null,
  lastname: null,
  member: false,
  phone: null,
  photo: null,
  zip: null,
}

export const use_public_book_course = defineStore('public_book_course', {
  state: () => ({
    participants: [],
    booking: {},
    contact_person: {
      lastname: null,
      firstname: null,
      email: null,
      phone: null,
      date_of_birth: null,
      age: null,
    },
    customer_message: '',
    items_count: {},
    group_fee: {
      id: null,
      spots: null,
    },
    show_summary: false,
  }),

  actions: {
    add_empty_participant() {
      this.participants.push(structuredClone(empty_participant))
    },

    build_payload(locale, skip_payment) {
      const use = { public_course: use_public_course(), tenant: use_tenant() }
      const slug = use.tenant.home.slug
      const pricings = use.public_course.pricings
      const find_pricing = id => pricings.find(p => p.id == id)

      const participants = this.participants.map(participant => ({
        ...participant,
        ticket: find_pricing(participant.pricing_id),
      }))

      const selected_tickets = []
      const add_ticket = (pricing_id, quantity, spots = null) => {
        let t = find_pricing(pricing_id)
        if (!t) return
        t = { ...t, quantity }
        if (spots) t.spots = spots
        selected_tickets.push(t)
      }

      if (this.group_fee.id) {
        add_ticket(this.group_fee.id, 1, this.group_fee.spots)
      } else {
        // NOTE: B.E. expects to receive on ticket with quantity=1 even if same ticket type,
        // so resist the urge to refactor it until fixing it there.
        for (const p of participants) {
          add_ticket(p.pricing_id, 1)
        }
      }
      for (const [pricing_id, count] of Object.entries(this.items_count)) {
        add_ticket(pricing_id, count)
      }

      return {
        task_id: use.public_course.course.id,
        locale,
        redirect_url_base: `${window.location.origin}/${slug}/booking`,
        return_url: window.location.href,
        booking: {
          firstname: this.contact_person.firstname,
          lastname: this.contact_person.lastname,
          email: this.contact_person.email,
          phone: this.contact_person.phone,
          age: this.contact_person.age,
          date_of_birth: this.contact_person.date_of_birth,
          participants,
          selected_tickets,
          ip: null,
          ticket_required: use.public_course.course.ticket_required, // TODO: verify if this the value and why is it needed
          skip_payment,
          message: this.customer_message,
        },
      }
    },
  },

  getters: {
    is_participant_empty: () =>
      // TODO 3255 add check if tickets are selected as soon as present
      function (participant) {
        return (
          !participant.address &&
          !participant.age &&
          !participant.city &&
          !participant.country &&
          !participant.date_of_birth &&
          !participant.email &&
          Object.keys(participant.extra_fields || []).length === 0 &&
          !participant.filename &&
          !participant.firstname &&
          !participant.lastname &&
          !participant.member &&
          !participant.phone &&
          !participant.photo &&
          !participant.zip
        )
      },

    is_participant_invalid: () =>
      function (p, date = null) {
        const settings = use_tenant().coursebooking_settings
        const b = Vue.$vl_utils.is_blank

        if (settings.ask_age && b(p.age)) return true
        if (settings.ask_birthday && b(p.date_of_birth)) return true
        if (date && !this.is_participant_age_valid(p, date, settings.minimum_age)) return true
        if (settings.ask_nationality && b(p.nationality)) return true
        if (settings.ask_email) {
          if ('email_valid' in p) {
            if (!p.email_valid) return true
          } else if (b(p.email)) return true
        }
        if (settings.ask_phone) {
          if ('is_phone_valid' in p) {
            if (!p.is_phone_valid) return true
          } else if (b(p.phone)) return true
        }
        if (settings.ask_city && b(p.city)) return true
        if (settings.ask_gender && b(p.gender)) return true
        if (settings.ask_full_address) {
          if (b(p.address) || b(p.city) || b(p.zip) || b(p.country)) return true
        }

        return b(p.firstname) || b(p.lastname)
      },

    // TODO [NQ-3255]: should age requirement be restricted to dob?
    is_participant_age_valid: () =>
      function (participant, date, min_age) {
        const settings = use_tenant().coursebooking_settings

        if (!settings.ask_age && !settings.ask_birthday) return true
        if (!min_age) return true

        // Validate age field
        if (settings.ask_age && participant.age < min_age) return false

        // Validate date of birth field
        if (settings.ask_birthday) {
          // Min age is required and age too low
          // NOTE: Avoid using luxon's .diff method here, result is not precise on year basis
          const limit = date.minus({ years: min_age }).toFormat('yyyy-MM-dd')
          if (limit < participant.date_of_birth) return false
        }

        return true
      },

    is_customer_age_valid: () =>
      function (participant, date, min_age) {
        const settings = use_tenant().coursebooking_settings

        if (!settings.ask_age_contact && !settings.ask_birthday_contact) return true
        if (!min_age) return true

        // Validate age field
        if (settings.ask_age_contact && participant.age < min_age) return false

        // Validate date of birth field
        if (settings.ask_birthday_contact) {
          // Min age is required and age too low
          // NOTE: Avoid using luxon's .diff method here, result is not precise on year basis
          const limit = date.minus({ years: min_age }).toFormat('yyyy-MM-dd')
          if (limit < participant.date_of_birth) return false
        }

        return true
      },

    participants_summary(state) {
      const pub_course = use_public_course()
      const pricing_name_by_id = Object.fromEntries(pub_course.pricings.map(p => [p.id, p.name]))
      const pricing_price_by_id = Object.fromEntries(pub_course.pricings.map(p => [p.id, p.price]))
      const pricing_vat_by_id = Object.fromEntries(pub_course.pricings.map(p => [p.id, p.vat]))

      return state.participants.map(({ photo_thumbnail_path, firstname, lastname, pricing_id }) => ({
        photo_thumbnail_path,
        firstname,
        lastname,
        participation_fee: {
          id: pricing_id,
          name: pricing_name_by_id[pricing_id],
          price: pricing_price_by_id[pricing_id],
          vat: pricing_vat_by_id[pricing_id],
        },
      }))
    },

    group_fee_summary(state) {
      const pub_course = use_public_course()
      const pricing_name_by_id = Object.fromEntries(pub_course.pricings.map(p => [p.id, p.name]))
      const pricing_price_by_id = Object.fromEntries(pub_course.pricings.map(p => [p.id, p.price]))
      const pricing_vat_by_id = Object.fromEntries(pub_course.pricings.map(p => [p.id, p.vat]))

      return {
        id: state.group_fee.id,
        spots: state.group_fee.spots,
        name: pricing_name_by_id[state.group_fee.id],
        price: pricing_price_by_id[state.group_fee.id],
        vat: pricing_vat_by_id[state.group_fee.id],
      }
    },

    items(state) {
      const items = use_public_course().items

      return items
        .filter(i => state.items_count[i.id])
        .map(({ id, name, price, vat }) => ({
          items_count: state.items_count[id],
          name,
          price,
          vat,
        }))
    },

    total_amount(state) {
      const pub_course = use_public_course()

      // Sum participants
      const pricing_by_id = Object.fromEntries(pub_course.pricings.map(p => [p.id, p]))
      const participants = this.participants.filter(p => p.pricing_id)
      if (participants.length === 0) return null
      let total = participants.reduce((s, p) => pricing_by_id[p.pricing_id].price + s, 0)

      // Sum items
      total += pub_course.items.reduce((s, i) => s + i.price * (state.items_count[i.id] || 0), 0)

      return total
    },
  },
})
