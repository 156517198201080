<template>
  <div class="menu-bar-container">
    <div class="left-side">
      <!-- <vl-icon icon="arrow_back" size="lg" pointer @click="back_arrow_clicked" /> -->
      <img
        v-if="use.tenant.settings?.logo_path"
        class="gym-logo"
        width="42"
        height="42"
        :src="`${use.tenant.cloudfront}${use.tenant.settings.logo_path}_thumb.jpg`"
      />
      <div class="header4" :class="{ 'hide-on-mobile': use.tenant.settings?.logo_path }">{{ use.tenant.home?.name }}</div>
    </div>

    <div class="right-side">
      <div v-if="use.public_actor.is_logged_in" class="dropdown">
        <a id="signoutMenuButton" class="cursor-pointer" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div class="vl-mr-4">
            <public-user-image :avatar_url="user_avatar_url" :firstname="user.firstname" :lastname="user.lastname" />
          </div>
          <span class="vl-pl-8 vl-pr-4 text-nowrap hide-on-mobile">{{ user.firstname }} {{ user.lastname }}</span>
          <vl-icon size="lg" icon="arrow_down" />
        </a>
        <div class="dropdown-menu mt-3 vl-dropdown-menu" aria-labelledby="signoutMenuButton" style="z-index: 2000">
          <a href="/logout" class="dropdown-item">{{ $t('header.logout') }}</a>
        </div>
      </div>

      <vl-button v-else @click="navigate_to(login_url)">{{ $t('core.login') }}</vl-button>

      <div class="align-self-center">
        <language-selector />
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSelector from '@public/components/language_selector.vue'
import PublicUserImage from '@public/components/public_user_image.vue'
import VlButton from '@core/shared/components/vl_button.vue'
import VlIcon from '@core/shared/components/vl_icon.vue'

export default {
  computed: {
    user() {
      if (!this.use.public_actor.is_logged_in) return null
      return this.use.public_actor.current_user
    },

    user_avatar_url() {
      // TODO implement correct avatar URL
      return this.user.avatar
    },

    login_url() {
      const url = document.head.querySelector('meta[name="login-url"]').content
      return `${url}/${this.$route.fullPath.substring(1)}`
    },
  },

  methods: {
    // back_arrow_clicked() {
    //   history.back()
    // },

    navigate_to(url) {
      window.location.href = url
    },
  },

  components: {
    LanguageSelector,
    PublicUserImage,
    VlButton,
    VlIcon,
  },
}
</script>

<style>
:root {
  --public-menu-height: 66px;
}
</style>

<style lang="scss" scoped>
.menu-bar-container {
  font-family: 'IBM Plex';
  position: sticky;
  top: 0;
  background-color: var(--neutral-white_primary);
  box-shadow: var(--vl-box-shadow);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding: 12px;
  height: var(--public-menu-height);
  z-index: 999;

  .left-side {
    display: flex;
    gap: 12px;
    align-items: center;

    .gym-logo {
      border-radius: 42px;
      object-fit: cover;
    }
  }

  .right-side {
    display: flex;
    gap: 40px;
    align-items: center;

    #signoutMenuButton {
      display: flex;
      align-items: center;
    }

    .user-round {
      margin-right: 4px;
      width: 36px;
      height: 36px;
      border-radius: 36px;

      &.user-image {
        object-fit: cover;
      }

      &.initials-circle {
        background-color: var(--neutral-100_primary);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .dropdown,
    .dropdown * {
      color: var(--neutral-black);
    }
  }
}
</style>
