import Vue from 'vue'
import { createPinia, PiniaVuePlugin } from 'pinia'

import { use_documents } from '@public/stores/documents_store'
import { use_first_available_slots } from '@public/stores/first_available_slots_store'
import { use_octopus_brain } from '@public/stores/octopus_brain_store'
import { use_octopus_tentacles } from '@public/stores/octopus_tentacles_store'
import { use_public_actor } from '@public/stores/public_actor_store'
import { use_public_book_course } from '@public/stores/calendar/public_book_course_store'
import { use_public_bookings } from '@public/stores/public_bookings_store'
import { use_public_calendar } from '@public/stores/calendar/public_calendar_store'
import { use_public_course } from '@public/stores/calendar/public_course_store'
import { use_public_course_template } from '@public/stores/calendar/public_course_template_store'
import { use_public_course_templates } from '@public/stores/calendar/public_course_templates_store'
import { use_public_devices } from '@public/stores/public_devices_store'
import { use_public_payments } from '@public/stores/public_payments_store'
import { use_public_prescriptions } from '@public/stores/public_prescriptions_store'
import { use_public_rental } from '@public/stores/public_rental_store'
import { use_public_signatures } from '@public/stores/public_signatures_store'
import { use_public_slots } from '@public/stores/public_slots_store'
import { use_public_time_range } from '@public/stores/public_time_range_store'
import { use_tenant } from '@public/stores/tenant_store'
import { use_utility } from '@public/stores/public_utility_store'

export { PiniaVuePlugin }

export async function create_pinia(extra_uses) {
  if (!window.vl_pinia_store) {
    console.info('VL creating new pinia store')
    window.vl_pinia_store = await createPinia()
    window.vl_use_all = await use_all(extra_uses)
  } else {
    while (!window.vl_use_all) {
      await Vue.$vl_utils.delay(10)
    }

    console.info('VL reusing existing pinia store')
  }
  return window.vl_pinia_store
}

async function use_all(extra_uses) {
  const uses = {
    use_documents,
    use_first_available_slots,
    use_octopus_brain,
    use_octopus_tentacles,
    use_public_actor,
    use_public_book_course,
    use_public_bookings,
    use_public_calendar,
    use_public_course,
    use_public_course_template,
    use_public_course_templates,
    use_public_devices,
    use_public_payments,
    use_public_prescriptions,
    use_public_rental,
    use_public_signatures,
    use_public_slots,
    use_public_time_range,
    use_tenant,
    use_utility,
    ...extra_uses,
  }

  const use = {}
  Object.entries(uses).forEach(([module_key, f]) => {
    const name = module_key.split('use_')[1]
    use[name] = f(window.vl_pinia_store)
  })

  return use
}
