import Vue from 'vue'
import VueCookies from 'vue-cookies'
import VueRouter from 'vue-router'

import '@core/../helpers/cssHelper'
import { create_pinia, PiniaVuePlugin } from '@public/lib/create'
import appsignal from '@core/../helpers/appsignal'
import create_auth_refresh_interceptor from '@core/../mixins/auto-auth-refresh'
import i18n from '@core/../helpers/localeHelper'
import Root from '@public/root.vue'
import VerticalLifePlugin from '@core/../plugins/VerticalLifePlugin'

Vue.use(VueCookies)
Vue.use(VueRouter)
Vue.use(VerticalLifePlugin)
Vue.use(PiniaVuePlugin)

create_auth_refresh_interceptor(Vue)

export function routify(path, component, name) {
  return { path, component, name }
}

const pub_signatures = () => import('@public/signatures/public_signatures_layout.vue')

// NOTE: Vue requires path to start with a slash, deny the temptation to make the regex cleaner:
export const routes = [
  // DOCUMENTS
  routify('/(\\w\\w/)?documents/:short_token/fill/:doc_uuid', () => import('@public/components/documents/fill_the_document.vue'), 'fill-the-document'),
  routify('/(\\w\\w/)?documents/:short_token', () => import('@public/components/documents/documents_loader.vue'), 'public-documents'),

  // RENTAL
  routify('/(\\w\\w/)?:slug/rental/iframe/:code', () => import('@public/rental/public_rental_iframe.vue'), 'rental-iframe'),

  // CHECKINS
  routify('/(\\w\\w/)?:slug/checkins', () => import('@public/checkins/public_checkins_layout.vue'), 'checkins-root'),
  routify('/(\\w\\w/)?:slug/checkins/service/:service_name/:area_id?/:date?', () => import('@public/checkins/public_checkins_layout.vue'), 'checkins-find'),
  routify(
    '/(\\w\\w/)?:slug/checkins/service/:service_name/:area_id/:date/:time/:spots',
    () => import('@public/checkins/public_checkins_layout.vue'),
    'checkins-book'
  ),
  routify('/(\\w\\w/)?:slug/checkins/:area_id/:date?', () => import('@public/checkins/public_checkins_layout.vue'), 'checkins-legacy'),
  routify('/(\\w\\w/)?:slug/booking/:token', () => import('@public/checkins/booking_status.vue'), 'checkins-booking-status'),
  routify('/(\\w\\w/)?:slug/cancel_booking/:token', () => import('@public/checkins/cancel_booking.vue'), 'checkins-cancel-booking'),
  routify('/(\\w\\w/)?:slug/locator', () => import('@public/octopus_search/public_octopus_search_layout.vue'), 'octopus-root'),

  // CALENDAR
  {
    path: '/(\\w\\w/)?:slug/events',
    component: () => import('@public/calendar/public_courses_layout.vue'),
    name: 'public-events-pages',
    redirect: { name: 'public-courses' },
    children: [
      routify('calendar/:task_group_ids?', () => import('@public/calendar/pages/public_calendar_and_courses.vue'), 'public-calendar'),
      routify('list', () => import('@public/calendar/pages/public_calendar_and_courses.vue'), 'public-courses'),
      {
        path: ':task_group_id(\\d+)',
        component: () => import('@public/calendar/pages/public_courses_detail.vue'),
        name: 'public-courses-detail',
        redirect: { name: 'public-courses-detail-list' },
        children: [
          routify('list', () => import('@public/calendar/pages/public_calendar_and_courses.vue'), 'public-courses-detail-list'),
          routify('calendar', () => import('@public/calendar/pages/public_calendar_and_courses.vue'), 'public-courses-detail-calendar'),
        ],
      },
      routify('course/:task_id', () => import('@public/calendar/pages/public_event.vue'), 'public-event'),
      routify('course/:task_id/book', () => import('@public/calendar/pages/public_book.vue'), 'public-book'),
    ],
  },

  // PRESCRIPTIONS
  routify('/(\\w\\w/)?:slug/prescriptions', () => import('@public/prescriptions/public_prescriptions_layout.vue'), 'prescriptions-root'),
  routify('/(\\w\\w/)?:slug/prescriptions/review/:token', () => import('@public/prescriptions/public_prescriptions_layout.vue'), 'prescriptions-view-order'),

  // SIGNATURES
  routify('/(\\w\\w/)?:slug/signatures/service/pdf', pub_signatures, 'signatures-pdf'),
  routify('/(\\w\\w/)?:slug/signatures/service/sign/:signature_id', pub_signatures, 'signatures-pad'),
  routify('/(\\w\\w/)?:slug/signatures/service/handover', pub_signatures, 'signatures-customer-handover'),
  routify('/(\\w\\w/)?:slug/signatures/service/edit', pub_signatures, 'signatures-edit'),
  routify('/(\\w\\w/)?:slug/signatures/service', pub_signatures, 'signatures-root'),
  routify('/(\\w\\w/)?signatures/register_device', () => import('@public/signatures/public_register_device.vue'), 'signatures-scan-device'),
  routify('/(\\w\\w/)?:slug/signatures/register_device/:token', () => import('@public/signatures/public_register_device.vue'), 'signatures-register-device'),
]
const router = new VueRouter({ mode: 'history', routes })

document.addEventListener('DOMContentLoaded', async () => {
  const node = document.getElementById('public_unified')
  const props = JSON.parse(node.getAttribute('data'))
  new Vue({
    i18n,
    pinia: await create_pinia(),
    render: h => h(Root, { props }),
    router,
  }).$mount(node)
})
